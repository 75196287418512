import * as echarts from 'echarts'
import china from 'echarts/map/json/china.json'
let mTime = null
// 部门异常统计
export const departmentAbnormal = (box, xList, object) => {
  const myChart = echarts.init(document.getElementById(box))
  const app = {}
  var option
  const posList = [
    'left',
    'right',
    'top',
    'bottom',
    'inside',
    'insideTop',
    'insideLeft',
    'insideRight',
    'insideBottom',
    'insideTopLeft',
    'insideTopRight',
    'insideBottomLeft',
    'insideBottomRight'
  ]
  app.configParameters = {
    rotate: {
      min: -90,
      max: 90
    },
    align: {
      options: {
        left: 'left',
        center: 'center',
        right: 'right'
      }
    },
    verticalAlign: {
      options: {
        top: 'top',
        middle: 'middle',
        bottom: 'bottom'
      }
    },
    position: {
      options: posList.reduce(function (map, pos) {
        map[pos] = pos
        return map
      }, {})
    },
    distance: {
      min: 0,
      max: 100
    }
  }
  app.config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange: function () {
      const labelOption = {
        rotate: app.config.rotate,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        position: app.config.position,
        distance: app.config.distance
      }
      myChart.setOption({
        series: [
          {
            label: labelOption
          },
          {
            label: labelOption
          },
          {
            label: labelOption
          },
          {
            label: labelOption
          }
        ]
      })
    }
  }
  option = {
    color: ['#3BDAFF', '#009EFF', '#0073FF'],
    title: {
      show: true,
      text: '部门异常统计'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      show: false,
      data: ['跟单异常', '时效异常', '车辆质损'],
      bottom: 0,
      left: 0
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: xList
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '跟单异常',
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series'
        },
        data: object.list1
      },
      {
        name: '时效异常',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: object.list2
      },
      {
        name: '质损异常',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: object.list3
      }
    ]
  }
  myChart.clear()
  option && myChart.setOption(option)
  myChart.hideLoading()
}
// 员工车辆质损统计
export const massLossData = (box, xList, legend, list) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: '员工车辆质损统计'
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: legend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xList
    },
    yAxis: {
      type: 'value'
    },
    series: list
  })
}
// 员工时效
export const ageingAbnomalData = (box, xList, legend, list) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.showLoading()
  var option
  option = {
    title: {
      text: '员工时效异常统计'
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: legend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xList
    },
    yAxis: {
      type: 'value'
    },
    series: list
  }
  myChart.clear()
  option && myChart.setOption(option)
  myChart.hideLoading()
}

// 数据监控统计↓↓↓
// 业务部门准时率
export const departmentData = (box, orgNameList, dataList) => {
  var chartDom = document.getElementById(box)
  var myChart = echarts.init(chartDom)
  var option
  var data = []

  dataList.forEach(item => {
    data.push({
      value: item.proportionNum,
      ...item
    })
  })

  option = {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        if (params[0].data) {
          let toolTiphtml = ''
          toolTiphtml = `<div style='color:#ffc023'>${params[0].name}</div>`
          toolTiphtml += `<div>总台量：${params[0].data.totalNum}</div>`
          toolTiphtml += `<div>超时台量：${params[0].data.timeoutNum}</div>`
          toolTiphtml += `<div>准时率：${params[0].data.punctuality + '%'}</div>`
          return toolTiphtml
        }
      }
    },
    grid: {
      width: '85%',
      height: '80%',
      left: '10%',
      bottom: '10%',
      right: '5%'
    },
    xAxis: {
      type: 'category',
      data: orgNameList,
      axisLabel: {
        color: '#fff'
      },
      axisLine: {
        lineStyle: {
          color: '#42a4ff'
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#fff',
        formatter: function (v) {
          return v + '%'
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#42a4ff'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#42a4ff'
        }
      }
    },
    series: [
      {
        zoom: 1.2,
        barWidth: '40%',
        itemStyle: {
          normal: {
            color: function () { return '#' + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16) }
          }
        },
        data: data,
        type: 'bar'
      }
    ]
  }
  myChart.off('resize')
  window.addEventListener('resize', function () {
    myChart.resize();
  })
  option && myChart.setOption(option)
}
// 中国地图区域
export const getChinaData = (box, dataList, chinaSearch) => {
  modelDemo()
  function modelDemo () {
    echarts.registerMap('china', china)
    // 基于准备好的dom，初始化echarts实例
    var myChart = echarts.init(document.getElementById(box))

    var name_title = ''
    var subname = ''
    var nameColor = ' rgb(55, 75, 113)'
    var name_fontFamily = '楷体'
    var name_fontSize = 52
    var mapName = 'china'
    var dataIndex = 0
    let maxDataIndex = 0
    // var mTime = null
    var data = []
    const indexList = []
    var option = []
    var geoCoordMap = {}
    var toolTipData = []
    if (mTime !== null) {
      clearInterval(mTime)
    }
    /* 获取地图数据 */
    myChart.showLoading()
    var mapFeatures = echarts.getMap('china', china).geoJson.features
    myChart.hideLoading()
    mapFeatures.forEach(function (v, i) {
      // 地区名称
      var name = v.properties.name
      // 地区经纬度
      // geoCoordMap[name] = v.properties.cp;
      const index = dataList.findIndex(item => item.id === v.properties.id)
      if (index >= 0) {
        data.push({
          name: name,
          queryId: dataList[index].queryId,
          value: dataList[index].transNum > 0 ? Number(dataList[index].proportionNum) : -50,
          transNum: dataList[index].transNum,
          timeoutNum: dataList[index].timeoutNum,
          punctuality: dataList[index].punctuality,
          rank: dataList[index].rank
        })
        if (dataList[index].transNum > 0) {
          indexList.push(i)
          maxDataIndex++
        }
      }
      toolTipData.push({
        name: name,
        value: [{
          name: '发运台量',
          value: Math.round(Math.random() * 100 + 10)
        },
        {
          name: '超时台量',
          value: Math.round(Math.random() * 100 + 10)
        },
        {
          name: '准时率',
          value: Math.round(Math.random() * 100 + 10)
        },
        {
          name: '排名',
          value: Math.round(Math.random() * 100 + 10)
        }
        ]
      })
    })
    indexList.sort((a, b) => b - a)
    option = {
      title: {
        show: false,
        text: name_title,
        subtext: subname,
        x: 'center',
        textStyle: {
          color: nameColor,
          fontFamily: name_fontFamily,
          fontSize: name_fontSize
        }
      },
      grid: {
        width: '100%',
        height: '100%',
        top: 'center',
        bottom: 'center',
        left: 'center',
        right: 'center',
      },
      tooltip: {
        trigger: 'item',
        extraCssText: 'z-index:1',
        formatter: function (params) {
          if (params.data) {
            let toolTiphtml = ''
            toolTiphtml = `<div style='color:#ffc023'>${params.data.name}</div>`
            toolTiphtml += `<div>发运台量：${params.data.transNum}</div>`
            toolTiphtml += `<div>超时台量：${params.data.timeoutNum}</div>`
            toolTiphtml += `<div>准时率：${params.data.punctuality + '%'}</div>`
            if (params.data.rank > 0) {
              toolTiphtml += `<div>排名：${params.data.rank + '名'}</div>`
            }
            return toolTiphtml
          }
        }
      },
      legend: {
        orient: 'vertical',
        y: 'bottom',
        x: 'right',
        data: ['credit_pm2.5'],
        textStyle: {
          color: '#fff'
        }
      },
      visualMap: {
        show: true,
        min: -50,
        max: 100,
        color: '#fff',
        left: 'left',
        top: 'bottom',
        text: ['高', '低'], // 文本，默认为数值文本
        calculable: true,
        // seriesIndex: [1],
        textStyle: { color: '#fff' },
        inRange: {
          color: ['#fff', '#9A0103', '#ffc023', '#2636FF']
        }
      },
      /* 工具按钮组 */
      toolbox: {
        show: false,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataView: {
            readOnly: false
          },
          restore: {},
          saveAsImage: {}
        }
      },
      geo: {
        show: true,
        map: mapName,
        center: [10, 50],
        label: {
          normal: {
            show: false
          },
          emphasis: {
            show: false
          }
        },
        roam: true,
        itemStyle: {
          normal: {
            areaColor: '#031525',
            borderColor: '#3B5077'
          },
          emphasis: {
            areaColor: '#2B91B7'
          }
        }
      },
      series: [
        {
          type: 'map',
          map: mapName,
          // geoIndex: 0,
          // aspectScale: 0.75, //长宽比
          zoom: 1.2,
          showLegendSymbol: false, // 存在legend时显示
          label: {
            normal: {
              color: '#fff',
              show: true
            },
            emphasis: {
              show: false,
              textStyle: {
                color: '#fff'
              }
            }
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: '#2736ff',
              borderColor: '#0227ad'
            },
            emphasis: {
              areaColor: '#2B91B7',
              borderType: 'solid',
              borderWidth: 2,
              borderColor: '#f00'
            }
          },
          animation: false,
          data: data
        }
      ]
    }
    myChart.setOption(option)
    myChart.off('click')
    myChart.off('mousemove')
    myChart.off('mouseout')
    myChart.on('click', function (params) {
      // 控制台打印数据的名称
      if (params.data.queryId) chinaSearch(Number(params.data.queryId))
    })
    if (mTime === null) {
      mTime = setInterval(() => {
        // 清除之前的高亮
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex === 0 ? maxDataIndex : dataIndex - 1]
        })
        // 当前下标高亮
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex]
        })
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex]
        })
        dataIndex++
        if (dataIndex >= indexList.length) {
          dataIndex = 0
        }
      }, 3000)
    }
    myChart.on('mousemove', (e) => {
      clearInterval(mTime)
      if(mTime !== null){
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex === 0 ? maxDataIndex : dataIndex - 1]
        })
        mTime = null
      }
    })
    myChart.on('mouseout', (e) => {
      if (mTime === null) {
        mTime = setInterval(() => {
          // 清除之前的高亮
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: indexList[dataIndex === 0 ? maxDataIndex : dataIndex - 1]
          })
          // 当前下标高亮
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: indexList[dataIndex]
          })
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: indexList[dataIndex]
          })
          dataIndex++
          if (dataIndex >= indexList.length) {
            dataIndex = 0
          }
        }, 3000)
      }
    })
    myChart.off('resize')
    // 使用刚指定的配置项和数据显示图表。
    window.addEventListener('resize', function () {
      myChart.resize()
    })
    setTimeout(() => {
      myChart.setOption(option)
    }, 10)
  }
}
// 年度总时率统计
export const yearStatisticsData = (box, yearTitles, data, yearSearch) => {
  var chartDom = document.getElementById(box)

  var myChart = echarts.init(chartDom)
  var option
  option = {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        if (params[0].data) {
          let toolTiphtml = ''
          toolTiphtml = ''
          toolTiphtml += `<div>发运台量：${params[0].data.transNum}</div>`
          toolTiphtml += `<div>超时台量：${params[0].data.timeoutNum}</div>`
          toolTiphtml += `<div>准时率：${params[0].data.punctuality + '%'}</div>`
          return toolTiphtml
        }
      }
    },
    grid: {
      top: '10%',
      left: '0%',
      right: '3.5%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        color: '#fff'
      },
      axisLine: {
        lineStyle: {
          color: '#42a4ff'
        }
      },
      boundaryGap: false,
      data: yearTitles
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#fff',
        formatter: function (v) {
          return v + '%'
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#42a4ff'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#42a4ff'
        }
      }
    },
    series: [
      {
        zoom: 1,
        data: data,
        type: 'line',
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: '#42a4ff'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#5b08c0'
            },
            {
              offset: 1,
              color: '#071755'
            }
          ])
        }
      }
    ]
  }
  myChart.off('click')
  myChart.on('click', function (params) {
    clearInterval(mTime)
    // 控制台打印数据的名称
    yearSearch(params.data.month)
    console.log(params)
  })
  myChart.off('resize')
  window.addEventListener('resize', function () {
    myChart.resize();
  })
  option && myChart.setOption(option)
}
